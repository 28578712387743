
import { Options, Vue } from 'vue-class-component'
import Recommendation from '@/models/Recommendation'
import Intervention from '@/models/Intervention'
import RatingCell from '@/components/RatingCell.vue'
import Analysis from '@/models/Analysis'
import RadioN from '@/components/RadioN.vue'

@Options({
  components: {
    RatingCell,
    RadioN
  },
  props: {
    recommendation: Recommendation,
    notRecommendation: Boolean,
    interventions: Array
  }
})
export default class RecommendationTable extends Vue {
  openedInterventions: Array<number> = []
  recommendation!: Recommendation
  interventions!: Intervention[]
  formatter = new Intl.NumberFormat('es-ES', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

  color (value: number, weight: string): string {
    switch (value) {
      case 4:
      case 3:
        return `bg-green-${weight}`
      case 2:
      case 1:
        return `bg-red-${weight}`
      case 0:
      default:
        return `bg-orange-${weight}`
    }
  }

  analysis (intervention: Intervention): Array<Analysis> {
    return this.recommendation.analysis.filter(
      (an) => an.pharmac.toLowerCase() === intervention.pharmacDose
    )
  }

  toggleOpenIntervention (anIdx: number): void {
    if (this.openedInterventions.includes(anIdx)) {
      this.openedInterventions.splice(
        this.openedInterventions.indexOf(anIdx),
        1
      )
    } else {
      this.openedInterventions.push(anIdx)
    }
  }

  capitalize (value: string): string {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
  }

  recommendationPhrase (intervention: Intervention): string {
    const phrase = [this.$t('App.TDApp')]

    const recommendation = intervention.recommendation
      .split(' ')
      .map((w) => this.capitalize(w))
      .join('')
    phrase.push(this.$t(`RecommendationTable.${recommendation}`))

    if (intervention.rank() > 0) {
      phrase.push(this.$t('RecommendationTable.use'))
    }

    phrase.push(this.$t(`RecommendationTable.${intervention.pharmacDose}`))

    return phrase.join(' ')
  }

  evidencePhrase (intervention: Intervention): string {
    const phrase = [
      this.$t('RecommendationTable.The'),
      this.$t('RecommendationTable.BenefitRiskRelationship').toLowerCase(),
      this.$t('RecommendationTable.of')
    ]

    phrase.push(this.$t(`RecommendationTable.${intervention.pharmacDose}`))

    phrase.push(this.$t('RecommendationTable.is'))

    phrase.push(this.$t(`RecommendationTable.Risk${intervention.risk}`))

    phrase.push(`(${this.$t('RecommendationTable.Quality')}`)

    phrase.push(
      `${this.$t(`RecommendationTable.Quality${intervention.quality}`)})`
    )

    return phrase.join(' ')
  }

  decimals (value: number): string {
    return this.formatter.format(value)
  }

  ratingColor (analysis: Analysis): string {
    const inferior = [
      'adhd_sympt_clin',
      'adhd_sympt_pat',
      'CGI',
      'drug_use',
      'drug_use_prevention',
      'accidents',
      'academic_achivement',
      'legal',
      'QoL'
    ]

    const superior = [
      'drop',
      'drop_ae',
      'appetite_decreased',
      'dizziness',
      'dry_mouth',
      'insomnia',
      'seizures',
      'tic',
      'vasovagal_syncope'
    ]

    if (inferior.includes(analysis.variable)) {
      if ((analysis.inferior_interval_q || 0) > 0.0) {
        return 'green'
      }

      if (
        (analysis.inferior_interval_q || 0) < 0.0 &&
        (analysis.superior_interval_q || 0) > 0.0
      ) {
        return 'yellow'
      }

      if ((analysis.superior_interval_q || 0) < 0.0) {
        return 'red'
      }
    }

    if (superior.includes(analysis.variable)) {
      if ((analysis.superior_interval_q || 0) < 1.0) {
        return 'green'
      }

      if (
        (analysis.superior_interval || 0) > 1.0 &&
        (analysis.inferior_interval_q || 0) < 1.0
      ) {
        return 'yellow'
      }

      if ((analysis.inferior_interval_q || 0) > 1.0) {
        return 'red'
      }
    }

    return 'grey'
  }

  smdOrRr (analysis: Analysis): string {
    const smd = [
      'adhd_sympt_clin',
      'adhd_sympt_pat',
      'CGI',
      'drug_use',
      'legal',
      'QoL',
      'academic_achivement'
    ]

    const rr = [
      'appetite_decreased',
      'dizziness',
      'dry_mouth',
      'insomnia',
      'n_drop_ae',
      'n_drop_ae_',
      'n_drop',
      'n_drop_',
      'seizures',
      'somnolence',
      'tic',
      'vasovagal_syncope',
      'vomiting',
      'accidents',
      'drug_use_prevention'
    ]

    if (smd.includes(analysis.variable)) return 'SMD'
    if (rr.includes(analysis.variable)) return 'RR'

    return ''
  }
}
