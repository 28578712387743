import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-5" }
const _hoisted_2 = { class: "flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RecommendationTable = _resolveComponent("RecommendationTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_RecommendationTable, {
      recommendation: _ctx.recommendation,
      notRecommendation: _ctx.notRecommendation,
      interventions: _ctx.interventions
    }, null, 8, ["recommendation", "notRecommendation", "interventions"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back())),
          type: "button",
          class: "bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        }, _toDisplayString(_ctx.$t('PatientRecommendation.Back')), 1),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.next())),
          type: "button",
          class: "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        }, _toDisplayString(_ctx.$t('PatientRecommendation.Next')), 1)
      ])
    ])
  ]))
}