
import { Options, Vue } from 'vue-class-component'
import Patient from '@/models/Patient'
import Recommendation from '@/models/Recommendation'
import Intervention from '@/models/Intervention'
import RecommendationTable from '@/components/RecommendationTable.vue'
import { mapActions, mapGetters } from 'vuex'

@Options({
  components: {
    RecommendationTable
  },
  methods: mapActions(['downloadRecommendation']),
  computed: mapGetters(['patient', 'recommendation', 'interventions'])
})
export default class PatientAnalysis extends Vue {
  patient!: Patient
  recommendation!: Recommendation
  notRecommendation = false
  interventions!: Intervention[]

  downloadRecommendation!: () => Promise<void>

  async created (): Promise<void> {
    try {
      await this.downloadRecommendation()
    } catch (error) {
      this.notRecommendation = true
    }
  }

  next (): void {
    this.$router.push({
      name: this.$route.meta.next,
      params: { id: this.patient.id }
    })
  }
}
