
import { Options, Vue } from 'vue-class-component'
import CircleOutlineIcon from '@/icons/outline/Circle.vue'
import CircleSolidIcon from '@/icons/solid/Circle.vue'
import StarOutlineIcon from '@heroicons/vue/outline/StarIcon'

@Options({
  components: {
    CircleOutlineIcon,
    CircleSolidIcon,
    StarOutlineIcon
  },
  props: {
    emptyStars: {
      type: Number,
      default: 3
    },
    filledCircles: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: 'grey'
    }
  }
})
export default class RatingCell extends Vue {
  emptyStars!: number
  filledCircles!: number
  color!: string
}
