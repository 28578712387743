import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CircleSolidIcon = _resolveComponent("CircleSolidIcon")!
  const _component_CircleOutlineIcon = _resolveComponent("CircleOutlineIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "flex flex-row justify-center",
      style: _normalizeStyle({ color: _ctx.color })
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filledCircles, (c) => {
        return (_openBlock(), _createBlock(_component_CircleSolidIcon, {
          class: "h-4 w-4",
          key: c
        }))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(4 - _ctx.filledCircles, (c) => {
        return (_openBlock(), _createBlock(_component_CircleOutlineIcon, {
          class: "h-4 w-4",
          "stroke-width": "1",
          key: c
        }))
      }), 128))
    ], 4)
  ]))
}